export type QuestionsContentType = {
  id: number
  title: string
  desc1: string
  desc2?: string
  desc3?: string
}

export const questionsContent: QuestionsContentType[] = [
  {
    id: 1,
    title: 'Кто может инвестировать в приложении? Нужен ли опыт?',
    desc1:
      'Клиентам FREEDOM не нужен опыт в трейдинге. Инвестировать в приложении может любое физическое лицо от 21-го года. Алгоритм приложения сам проводит торговые операции с помощью ИИ.',
    desc2:
      'От Вас требуется только настраивать свои торговые стратегии и выбирать интересующие Вас ценные бумаги.',
  },
  {
    id: 2,
    title: 'Что такое торговый робот FREEDOM AI? Как он работает?',
    desc1:
      'Трейд-робот FREEDOM AI - это инструмент для торговли активами на основе искусственного интеллекта.',
    desc2:
      'Робот сам принимает решения о покупке или продажи активов. В его код внедрены сложные алгоритмы анализа - за счет них его точность варьируется в пределах 73,9%. В долгосрочной перспективе такой шанс удачного прогноза несет гарантированную прибыль.',
  },
  {
    id: 3,
    title: 'Сколько я могу заработать, инвестируя с FREEDOM FINANCE?',
    desc1:
      'Доход с дивидендов зависит от инвестируемой клиентом суммы. Базовая прибыль варьируется от 6 000 000 UZS  за один календарный месяц при стандартной торговой стратегии.',
  },
  {
    id: 4,
    title: 'Могу ли я потерять свои деньги?',
    desc1:
      'Инвесторы FREEDOM не несут рисков потери своих депозитов. Наша система ведет дифференцированную инвестиционную тактику.',
    desc2:
      'Простыми словами, приложение не вкладывает все деньги клиента в один актив - торговый робот закупает на Ваши деньги несколько пакетов активов (акции, бонды, облигации и т.д.)',
    desc3:
      'Даже в случае непредвиденных сбоев со стороны торговой биржи FREEDOM FINANCE обязуется полностью компенсировать размер депозита за счет ORMB.',
  },
]
